document.addEventListener(
  'click',
  (event) => {
    const link = event.target.closest('a')

    const preserveParams = link?.getAttribute?.('data-preserve-params')
    if (!preserveParams) {
      return
    }

    const currentUrl = new URL(window.location.href)
    if (!(preserveParams === 'true' || currentUrl.pathname.startsWith(preserveParams))) {
      return
    }

    const currentParams = new URLSearchParams(window.location.search)
    if (!currentParams.toString()) {
      return
    }

    const url = new URL(link.href, window.location.origin)
    url.search = new URLSearchParams({
      ...Object.fromEntries(currentParams),
      ...Object.fromEntries(new URLSearchParams(url.search)),
    })

    link.href = url.toString()
  },
  { capture: true }
)
